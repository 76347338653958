.header {
	padding: 20px;
	background: white;
}
.container{
  
	align-items: center;
	display: flex;
	justify-content: center;
}
.span-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}
.divider{
  border-left: 0.5px solid white;
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
}