body {
  margin: 0;
  font-family: "EuclidCircularARegular";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "EuclidCircularARegular";
  src: local("EuclidCircularARegular"),
   url("./assets/fonts/EuclidCircularARegular.otf") format("truetype");
  }

  @font-face {
    font-family: "EuclidCircularASemibold";
    src: local("EuclidCircularASemibold"),
     url("./assets/fonts/EuclidCircularASemibold.otf") format("truetype");
    }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
