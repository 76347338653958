.footer-div {
    background: #000D34;
	padding: 40px 0;
}

.footer-coming-soon {
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.5em;
    margin-top: 0;
    margin-left: 15px;
    /* Dark Blue/100 */

    color: #C6E3F7;
}

.footer-image-store {
    width: 140px;
}

.footer-store-btn {
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.footer-div-store {
    margin-top: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.footer-ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding: 0;
}

.footer-li {
    margin-left: 15px;
	margin-right: 15px;
    display: flex;
}

.footer-link {
    color: #637895;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
}

.footer-link:hover {
    color: #a0adc0;
    transition: 0.3s;
}

.footer-social-icon {
    margin-bottom: -4px;
    margin-right: 5px;
    filter: invert(47%) sepia(43%) saturate(288%) hue-rotate(175deg) brightness(89%) contrast(90%);
}


.copyright {
    color: white;
    margin-top: 8px;
}

.copyright-div {

    margin-top: 35px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    
}


@media (max-width: 350px) {

    .footer-li {
        margin-left: 5px;
        margin-right: 5px;
        display: flex;
    }
}

@media (max-width: 520px) {
    .logo-desktop{
        display: none;
    }
    .logo-mobile{
        display: initial;
        width: 128px;
    }
}
@media (min-width: 520px) {
    .logo-desktop{
        display: initial;
        width: 150px;
    }
    .logo-mobile{
        display: none;
    }
}